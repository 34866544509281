/* global config, angular, moment, PDFJS, Promise, parseInt */

'use strict';

angular.module('managerApp').controller('PlaquesImmatriculationController', (
        $scope, $rootScope, $routeParams, $mdDialog,
        toast, procedureService, procedureTools, Auth) => {

    $scope.currentTab = 2;

    $scope.messages = procedureTools.messages;
    $scope.config = config;

    // init
    $scope._type = 'plaques-immatriculation';
    $scope.messages = procedureTools.messages;

    $scope.procedureId = $routeParams.id;
    $scope.isItem = typeof $scope.procedureId !== 'undefined';

    $scope.pagination = {
        currentPage: 1,
        maxSize: 5,
        itemsPerPage: 25,
        totalItems: 0,
        pageChanged: () => {
            getProcedures();
        }
    };

    if ($scope.isItem) {
        getProcedure($scope.procedureId);
    } else {
        getProcedures();
    }

    function getProcedure(id) {
        procedureService.get({
            id: id,
            type: $scope._type
        }).then((data) => {

            $scope.procedure = data;
            $scope.client = $scope.procedure.Client;
            $scope.item = $scope.procedure.PlaquesImmatriculation;
            $scope.item.payments = [];
            $scope.procedure.history = [];

            $scope.procedure.isHandled = procedureTools.isHandled($scope.procedure);
            $scope.procedure.isHandledByUser = $scope.procedure.UserId === $rootScope.currentUser._id;
            $scope.procedure.canBeHandledByUser = !$scope.procedure.isHandled &&
                    !$scope.procedure.isHandledByUser;

            $scope.procedure.isReadyToBeValidated =
                    $scope.procedure.allDocumentsAccepted === true &&
                    $scope.procedure.allDocumentsUploaded === true;

            $scope.procedure.isReadyForProcessing = $scope.item.status === 'validated';
            if ($scope.procedure.isReadyForProcessing) {
                $scope.procedure.isReadyToBeValidated = false;
            }

            $scope.procedure.origin = procedureTools.getOrigin($scope.procedure);
            $scope.item.count = 0;

            for (let i = 0; i < $scope.item.Plaques.length; i++) {
                let plaque = $scope.item.Plaques[i];
                $scope.item.count += plaque.count;
                plaque.materialName = procedureTools.getMaterialName(plaque.material);
                plaque.customizationName = procedureTools.getCustomizationName(plaque.customization, plaque.customizationText);

                if (plaque.back) {
                    $scope.Back = plaque;
                } else {
                    $scope.Front = plaque;
                }
            }

            if ($scope.item.Shipment) {
                $scope.shipment = $scope.item.Shipment;
                $scope.shipment.isRelay = $scope.shipment.type === 'relay';
                if ($scope.shipment.isRelay) {
                    $scope.shipment.PointRelais = JSON.parse($scope.shipment.Address.PointRelais);
                    console.log($scope.shipment.PointRelais)
                }
            }

            procedureTools.initVehicle($scope);
            procedureTools.initDocuments($scope);

            $scope.$apply();
        });
    }

    function getProcedures() {
        let params = {
            type: $scope._type
        };
        if ($scope.pagination.filters) {
            let filters = $scope.pagination.filters;
            for (let i in filters) {
                if (i === 'date') {
                    params[i] = moment(filters[i]).format(config.date.defaultFormat);
                } else {
                    params[i] = filters[i];
                }
            }
        }
        procedureService.get(params, $scope.pagination.currentPage,
                $scope.pagination.itemsPerPage).then((data) => {
            $scope.proceduresCount = data.count;
            $scope.procedures = data.items;
            for (let i = 0; i < $scope.procedures.length; i++) {
                let procedure = $scope.procedures[i];
                procedure.origin = procedureTools.getOrigin(procedure);
                procedure.isHandled = procedureTools.isHandled(procedure);
            }
            $scope.pagination.totalItems = data.count;
            $scope.$apply();
        });
    }

    $scope.checkDocument = (document) => {
        if ($scope.procedure.isHandledByUser) {
            if (document.concordance) {
                let msg = 'Vous ne pouvez pas valider ce document car il ' +
                        'est nécessaire de vérifier sa concordance par ' +
                        'rapport ' + document.concordance.documentLabel + ' ',
                        l = msg.length;
                if (document.concordance.document === null) {
                    msg += 'qui n\'a pas encore été téléversé.';
                } else if (document.concordance.document.status !== 'document-accepted') {
                    msg += 'qui n\'a pas encore été validé.';
                }
                if (msg.length > l) {
                    toast.show(msg, 'error', true);
                    return;
                }
            }
            document.validation = procedureTools.getDocValidationQuestions(document, $scope._type);
            procedureTools.openDocumentsViewer(document, $scope);
        } else {
            toast.show('Vous devez prendre en charge cette démarche' +
                    ' pour pouvoir exécuter cette action.', 'error', true);
        }
    };
    $scope.validate = (procedureId) => {
        if ($scope.procedure.isHandledByUser) {
            procedureService.validate(procedureId).then((result) => {
                toast.show('Cette procédure a bien été validé et' +
                        ' le client a reçu une notification.', 'success');
            }).catch((err) => {
                toast.show(err && err.message ? err.message :
                        'Une erreur est survenue lors du traitement de votre demande.', 'error');
            });
        } else {
            toast.show('Vous devez prendre en charge cette démarche' +
                    ' pour pouvoir exécuter cette action.', 'error', true);
        }//
    };
    $scope.handleUnhandle = (procedureId, unhandle = false) => {
        procedureService.handleUnhandle(procedureId, unhandle).then((response) => {
            toast.show(
                    unhandle ?
                    'La prise en charge de cette démarche a bien été annulée.' :
                    'La démarche a bien été prise en charge. ', 'success', true, 10000);
        }).catch((err) => {
            toast.show(err && err.message ? err.message :
                    'Une erreur est survenue lors du traitement de votre demande.', 'error', true, 10000);
        });
    };

    $scope.notify = (procedure, type = null) => {
        if ($scope.procedure.isHandledByUser) {
            procedureService.notify(procedure._id, type).then((result) => {
                toast.show('Une notification de relance pour le téléversement des documents ' +
                        'a bien été envoyée au client.', 'success');
            }).catch((err) => {
                toast.show(err && err.message ? err.message :
                        'Une erreur est survenue lors du traitement de votre demande.');
                console.error(err);
            });
        } else {
            toast.show('Vous devez prendre en charge cette démarche' +
                    ' pour pouvoir exécuter cette action.', 'error', true);
        }//
    };
    $scope.getHistory = (procedureId = null) => {
        if ($scope.procedure.history.length > 0) {
            return;
        }
        procedureService.getHistory(procedureId).then((history) => {
            $scope.procedure.history = history;
            for (let i = 0; i < history.length; i++) {
                let historyITem = history[i];
            }
        }).catch((err) => {
            toast.show(err && err.message ? err.message :
                    'Une erreur est survenue lors du traitement de votre demande.', 'error', false, 7500);
        });
    };
    $scope.createShippingLabel = () => {
        console.log($scope.procedure);
        procedureService.createShippingLabel({
            ProcedureId: $scope.procedure._id,
            weight: 2356
        }).then((r) => {
            console.log(r);
        }).catch((err) => {
            toast.show(err && err.message ? err.message :
                    'Une erreur est survenue lors du traitement de votre demande.', 'error', false, 7500);
        });
    };
});